import ApiService from "@/core/services/api.service";

export default {

    getSettingHEAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/produksi/setting-he/all", { params: credentials })
    },
    verifSettingHE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/produksi/setting-he", credentials)
    },

    getPullchickAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/nasional/produksi/pullchick/all", { params: credentials })
    },
    verifPullchick(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/nasional/produksi/pullchick", credentials)
    },



    getPerbaikanSettingHEAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/produksi/setting-he/all", { params: credentials })
    },
    verifPerbaikanSettingHE(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/produksi/setting-he", credentials)
    },

    getPerbaikanPullchickAll(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/verifikasi/perbaikan/nasional/produksi/pullchick/all", { params: credentials })
    },
    verifPerbaikanPullchick(credentials) {
        ApiService.setHeader();
        return ApiService.put("v2/verifikasi/perbaikan/nasional/produksi/pullchick", credentials)
    },
}